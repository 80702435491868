<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row>
        <b-col class="text-right">
          <b-button
            to="/warehouses/create"
            size="sm"
            variant="success"
            class="mb-1"
          >
            <feather-icon icon="PlusSquareIcon" />
            {{$t('Create')}}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="warehouses && Object.keys(warehouses.data).length > 0">
        <template v-for="(data, index) in warehouses.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card no-body>
              <b-row>
                <b-col md="9" cols="8">
                  <b-card-body>
                    <b-card-title>{{data.name}}</b-card-title>
                    <b-row class="m-0">
                      #{{data.id}}
                      <!-- <b-badge class="d-block ml-1" :variant="data.is_active ? 'light-success' : 'light-danger'">
                        {{data.is_active ? $t('Active') : $t('Not Active')}}
                      </b-badge> -->
                    </b-row>
                  </b-card-body>
                </b-col>
                <b-col md="3" cols="4" align-self="center" class="text-center">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    :to="`/warehouses/${data.id}`"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    v-on:click="deleteWarehouses(data)"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination v-if="warehouses" :limit="7" :data="warehouses" @pagination-change-page="getWarehouses"></pagination>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'Warehouses',
  data() {
    return {
      warehouses: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getWarehouses();
  },
  methods: {
    getWarehouses(page = 1){
      this.isLoading = true
      useJwt.get('/warehouses/get-all',{params: {
          page: page,
      }})
      .then((response) => {
        // console.log(response.data.data)
        this.warehouses = response.data.data
        // console.log(this.warehouses)
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    deleteWarehouses(warehouses) {
        if(confirm("Do you really want to delete page #"+warehouses.id+"?")){
            this.isLoading = true
            useJwt.post('/warehouses/destroy',warehouses)
            .then((response) => {
              this.warehouses = this.getWarehouses()
              this.isLoading = false
            })
            .catch(response => {
              // console.log(response);
            });
        }
    }
  }
}
</script>
